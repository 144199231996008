export const environment = {
  production: true,
  stage: 'qa',
  multiInvoiceFeatureFlag: true,
  invoiceLinesFeatureFlag: true,
  approvalFlowFeatureFlag: true,
  app_base_api_url: 'https://api.smartpdf.baswareqa.com',
  app_base_url: 'https://smartpdf.baswareqa.com',
  bw_access_url: 'https://access.baswareqa.com',
  appVersion: require('../../package.json').version
};
